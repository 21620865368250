<template>
  <Dropdown class="language-switcher">
    <TopNavigationElement
      :icon-alt="$t('notices.languagePreference')"
      :text="$i18n.locale"
    >
      <template slot="icon">
        <GlobeIcon class="globe-icon" />
      </template>
    </TopNavigationElement>
    <template slot="content">
      <a
        v-for="language in languages"
        :key="language"
        :class="[
          'dropdown-item language',
          { selected: language === $i18n.locale }
        ]"
        @click.prevent="$i18n.setLanguagePreference(language)"
      >
        <span>{{ $t(`notices.${language}`) }}</span>
        <span class="short-hand">({{ language }})</span>
      </a>
    </template>
  </Dropdown>
</template>

<script>
import Dropdown from '@sephora-asia/core-dropdown/Dropdown.vue'
import TopNavigationElement from '@sephora-asia/core-top-navigation-element/TopNavigationElement.vue'
import GlobeIcon from '~/assets/images/globe.svg?inline'

export default {
  name: 'TopNavigationLanguageSwitcher',

  components: {
    Dropdown,
    TopNavigationElement,
    GlobeIcon
  },

  props: {
    languages: {
      type: Array,
      required: true
    }
  }
}
</script>

<style lang="scss" scoped>
.language-switcher {
  margin-right: 20px;

  /deep/ .top-navigation-element-text {
    text-transform: uppercase;
  }

  .language {
    .short-hand {
      text-transform: uppercase;
    }

    &.selected {
      cursor: initial;
    }
  }

  .globe-icon {
    width: 24px;
    height: 24px;
    fill: $white;
    margin-right: 10px;
  }
}
</style>
